.outer-input-div {
    display: flex;
    align-items: center;
    border: 5px solid gray;
   border-radius: 15px;
    padding-left: 20px;
    height: 30px;
  }
  
  .input-field {
    height: 20px;
  }

  .icon-button {
    border: none;
    background: none;
    padding: 0;
    font-size: inherit;
    cursor: pointer;
  }


  ///
 .turnOnCameraButtonClass {
  display: none;
}

/* Hide any other specific buttons after recording */
.otherButtonClass {
  display: none;
}