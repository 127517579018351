.outer-input-div {
  
    border-radius: 15px;
    padding-left: 20px;
    height: 40px !important;
    background-color: #f7f7f7 !important;
     width: 75%;
   
  @media (max-width: 1024.99px) {
    width: 100%;
  }
  }
  
  .input-field {
    background-color: transparent !important;
   height: 20px !important;
  }

  .patient-responsive-btn{

    float: right;
    @media (max-width: 776.99px) {
      float: left !important;
    }
  }