.spinning-icon {
  display: inline-block;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.react-tel-input {
  width: 100% !important;
}
.react-tel-input .form-control {
  height: 43px !important;
  /* width: 349px !important; */
  width: 100% !important;
  border: none !important;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
}
.react-tel-input .flag-dropdown {
  border: none !important;
  /* box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important; */
}

.active-tab {
  background-color: rgb(255, 255, 254) !important; /* Set the background color to transparent */
  border-color: transparent !important; /* Set the border color to transparent */
  color: rgb(129, 129, 129) !important; 
  border-radius: 6px;/* Set the text color to inherit */
}
.active-tab :hover {
  border-color: none !important; /* Set the border color to transparent */
  color: rgb(129, 129, 129) !important;
}
.tabBackground {
  background-color: rgb(238, 238, 238) !important;
}

.pointerEvent{
  cursor: pointer;
}

.nav-link:hover {
  color: rgb(129, 129, 129) !important;
}

@media (max-width: 576px) {
  .btn-width-full-sm {
    width: 100%;
  }
}
/* I am hidding the button on recording session, when i complete the sesson there is showing the button use anohter video, */
.bGQrkY{
display: none !important; 
}


.SessiontabBackground {
  background-color: rgb(238, 238, 238) ;
  height: max-content;
}

.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 54px; */
  width: 200px;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.5s;
  position: relative;
  padding-top: 6px;
}


input[id="radio-1"]:checked ~ .glider {
  transform: translateX(0);
}

input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%);
}

.glider {
  position: absolute;
  display: flex;
  height:35px;
  width: 200px;
  color: white ;
  background-color: rgb(255, 254, 255);
  z-index: 1;
  border-radius: 6px;
  transition: 0.25s ease-out;
  transform: translateX(0);
}

@media (max-width: 496px) {
  .SessiontabBackground {
    margin-bottom: 15px;
    /* background-color: rgb(105, 105, 102) */
  }
  .active-tab {
    border-color: transparent !important; /* Set the border color to transparent */
    color: rgb(111, 118, 128) !important; 
    border-radius: 6px;/* Set the text color to inherit */
    
  }
  
  .tab {
    width: 100%;
    /* padding-right: 15px; */
    padding: 5px;
  }

  .glider {
    z-index: -1;
  }

}


